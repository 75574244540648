import KnBackToTopButton from '../../../shared/components/KnBackToTopButton.vue';
import KnFormActionButtons from '../../../shared/components/KnFormActionButtons/KnFormActionButtons.vue';
import KnFormNoteOfMandatory from '../../../shared/components/KnFormNoteOfMandatory.vue';
import KnFormSubtitle from '../../../shared/components/KnFormSubtitle/KnFormSubtitle.vue';
import KnFormTitle from '../../../shared/components/KnFormTitle.vue';
import KnLocalAlert from '../../../shared/components/KnLocalAlert.vue';
import KnAutoComplete from '../../../shared/components/KnAutoComplete.vue';
import KnSubjectsAutocomplete from '../KnSubjectsAutocomplete/KnSubjectsAutocomplete.vue';
import KnTabs from '../../../shared/components/KnTabs.vue';
import KnTextArea from '../../../shared/components/KnTextArea.vue';
import KnTextField from '../../../shared/components/KnTextField.vue';

import { fetchSchoolCycles } from '../../../configuration/helpers/KnGroupsOptions';
import {
  fetchSchoolLevels,
  fetchSubjects,
  fetchEvaluationPeriods,
  postSyllabus,
  putSyllabus,
  postAssignSyllabusToGroup,
  deleteAssignSyllabusToGroup,
} from '../../helpers/syllabusOptions';
import { fetchGroups } from '../../helpers/reportCardOptions';

import { getItem } from '../../../../api/api-methods';
import { getFullName } from '../../../shared/helpers/dataUtils';

import {
  getLocalData,
  removeLocalData,
  setLocalData,
} from '../../../income/helpers/incomeStorage';
import { mapState } from 'vuex';

import {
  canAdd,
  canChange,
  insufficientPermissionsMessage,
} from '../../../shared/helpers/permissionsUtils';
export default {
  name: 'KnFormSyllabus',
  components: {
    KnAutoComplete,
    KnBackToTopButton,
    KnFormActionButtons,
    KnFormNoteOfMandatory,
    KnFormSubtitle,
    KnFormTitle,
    KnLocalAlert,
    KnSubjectsAutocomplete,
    KnTabs,
    KnTextArea,
    KnTextField,
  },
  mixins: [],
  props: {
    entity: {
      type: Object,
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tabs: [
        { name: 'Información básica', value: 50 },
        { name: 'Materias', value: 100 },
      ],
      valueDeterminate: 50,
      routerName: 'Planes de estudio',
      resource: 'planestudio',
      bkEntity: null,
      bkReadonly: false,
      syllabus: {
        nombre: null,
        descripcion: '',
        fecha_inicio: '',
        fecha_fin: '',
        nivel_educativo: null,
        ciclo_escolar: null,
        periodo_evaluacion: [],
        materias_curriculares: [],
        materias_extracurriculares: [],
        grupo: null,
      },
      selectedGroups: [],
      bkSelectedGroups: [],
      subject: null,
      cycles: [],
      levels: [],
      periods: [],
      groups: [],
      curricularSubjects: [],
      extracurricularSubjects: [],
      syllabusId: null,
      loadingOwner: false,
      loadingGroups: false,
      /** Variables para alerta */
      errors: [],
      warnings: [],
      loading: false,
      showAlert: false,
      alertType: 'success',
      alertText: 'Registro exitoso',
      alertColor: null,
      /*********************** */
    };
  },
  computed: {
    ...mapState(['institutionId', 'userData']),
    isNewMode() {
      return this.entity === null || this.bkEntity === null;
    },
    title() {
      return this.isNewMode ? 'Registro de planes' : 'Detalle de planes';
    },
    isReadonly() {
      return this.readonly || this.bkReadonly;
    },
    successAlertText() {
      return this.isNewMode
        ? 'Plan registrado con exito!'
        : 'Plan actualizado con exito!';
    },
    successAlertType() {
      return this.isNewMode ? 'success' : 'info';
    },
    warningText() {
      return this.warnings.length ? this.warnings.join(',') : null;
    },
  },
  async created() {
    this.loading = true;

    this.bkEntity = this.entity;
    this.bkReadonly = this.readonly;

    this.alertText = 'Cargando... Por favor, espera';
    this.alertType = 'info';
    this.showAlert = true;

    const localData = getLocalData();
    if (localData) {
      Object.assign(this.$data, localData.data);
    } else {
      await this.fetchData();
      this.syllabus.institucion_educativa = this.institutionId;
      if (!this.isNewMode) {
        this.syllabusId = this.entity.id;
        this.fillSyllabus();
      }
      this.showAlert = false;
      setLocalData({
        data: this.$data,
        props: { entity: this.entity, readonly: this.readonly },
      });
    }
  },
  updated() {
    // console.log('Hubo un cambio', this.$data);
    setLocalData({
      data: this.$data,
      props: { entity: this.entity, readonly: this.readonly },
    });
  },
  beforeRouteLeave(to, from, next) {
    // console.log('beforeLeave to: ', to, ' from: ', from);
    removeLocalData();
    next();
  },
  methods: {
    setTabValue(val) {
      this.valueDeterminate = val;
    },
    async fetchData() {
      // Se deben obtener los valores necesarios para
      // llenar los selects
      const { ok: okSchoolLevels, data: dataSchoolLevels } =
        await fetchSchoolLevels({
          institutionId: this.institutionId,
          systemStatus: true,
          limit: 100,
        });
      const { ok: okSchoolCycles, data: dataSchoolCycles } =
        await fetchSchoolCycles({
          institutionId: this.institutionId,
          systemStatus: true,
          limit: 100,
        });
      const { ok: okEvaluationPeriod, data: dataEvaluationPeriod } =
        await fetchEvaluationPeriods({
          institutionId: this.institutionId,
          systemStatus: true,
          limit: 100,
        });
      const { ok: okSubjects, data: dataSubjects } = await fetchSubjects({
        institutionId: this.institutionId,
        extracurricular: false,
        systemStatus: true,
        limit: 2000,
      });
      const { ok: okExtraSubjects, data: dataExtraSubjects } =
        await fetchSubjects({
          institutionId: this.institutionId,
          extracurricular: true,
          systemStatus: true,
          limit: 2000,
        });
      this.cycles = okSchoolCycles ? dataSchoolCycles : [];
      this.levels = okSchoolLevels ? dataSchoolLevels : [];
      this.periods = okEvaluationPeriod ? dataEvaluationPeriod : [];
      this.curricularSubjects = okSubjects ? dataSubjects : [];
      this.extracurricularSubjects = okExtraSubjects ? dataExtraSubjects : [];

      // if (!this.isReadonly) {
      //   this.loadingOwner = true;
      //   for (const item of this.curricularSubjects) {
      //     item.owner = await this.getGroupOwner(item.titular_materia);
      //   }
      //   for (const item of this.extracurricularSubjects) {
      //     item.owner = await this.getGroupOwner(item.titular_materia);
      //   }
      //   this.loadingOwner = false;
      // }
    },
    async getGroups() {
      if (!this.isReadonly) {
        this.loadingGroups = true;
        const { ok, data, message } = await fetchGroups({
          institutionId: this.institutionId,
          schoolCycleId: this.syllabus.ciclo_escolar,
          systemStatus: true,
          limit: 1000,
        });
        if (ok) {
          this.groups = data;
        } else {
          console.log('Error al obtener grupos', message);
        }
        this.loadingGroups = false;
      }
    },
    async getGroupOwner(owner) {
      try {
        this.loadingOwner = true;
        const personalData = await getItem(
          `/app-personas/datos-personales/${owner.datos_personales}`
        );
        const fullName = personalData.e
          ? 'Sin nombre'
          : getFullName(personalData);
        return fullName;
      } catch (error) {
        console.error('Error al intentar obtener titular de grupo');
      }
    },
    async fillSyllabus() {
      // En modo edicion se debe llenar
      this.syllabus = { ...this.entity };
      this.syllabusId = this.syllabus.id;
      this.syllabus.ciclo_escolar = this.entity.ciclo_escolar
        ? this.entity.ciclo_escolar.id
        : null;
      this.syllabus.nivel_educativo = this.entity.nivel_educativo
        ? this.entity.nivel_educativo.id
        : null;
      this.syllabus.periodo_evaluacion = this.entity.periodo_evaluacion.map(
        (period) => period.id
      );
      this.syllabus.materias_curriculares =
        this.entity.materias_curriculares.map((subject) => subject.id);
      this.syllabus.materias_extracurriculares =
        this.entity.materias_extracurriculares.map((subject) => subject.id);
      this.syllabus.institucion_educativa =
        this.entity.institucion_educativa.id;
      // console.log('plan de estudios lleno', this.syllabus);

      const { ok, data, message } = await fetchGroups({
        institutionId: this.institutionId,
        syllabusId: this.syllabusId,
        systemStatus: true,
      });
      if (ok) {
        if (this.isReadonly) {
          this.groups = data;
        }
        this.selectedGroups = data.map((group) => group.id);
        this.bkSelectedGroups = data.map((group) => group.id);
      } else {
        console.log(
          'No se pudieron obtener los grupos por plan de estudios',
          message
        );
      }
    },
    completeAlert() {
      this.loading = false;
      if (this.errors.length) {
        this.alertType = 'error';
        this.alertText = this.errors.join(', ');
      } else {
        this.alertType = this.successAlertType;
        this.alertColor = 'success';
        this.alertText = this.successAlertText;
      }
    },
    insufficientPermissionAlert() {
      this.alertType = 'info';
      this.alertText = insufficientPermissionsMessage();
      this.alertColor = 'warning';
      this.loading = false;
      this.showAlert = true;
    },
    cancel() {
      this.$router.push({ name: this.routerName });
    },
    actionAlertBtn1() {
      if (this.alertType === 'success' || this.alertType === 'info') {
        this.returnToTable();
      } else {
        this.closeAlert();
      }
    },
    continueAdding() {
      this.clean();
      this.closeAlert();
    },
    returnToTable() {
      this.$router.replace({ name: this.routerName });
    },
    closeAlert() {
      this.errors = [];
      this.showAlert = false;
    },
    clean() {
      this.syllabus = {
        nombre: null,
        descripcion: '',
        fecha_inicio: '',
        fecha_fin: '',
        nivel_educativo: null,
        ciclo_escolar: null,
        periodo_evaluacion: [],
        materias_curriculares: [],
        materias_extracurriculares: [],
        grupo: null,
        institucion_educativa: this.institutionId,
      };
    },
    async createSyllabus() {
      try {
        this.alertText = 'Creando plan de estudios';
        const { ok, data, message } = await postSyllabus(this.syllabus);
        if (!ok) {
          this.errors.push(message);
        } else {
          this.syllabusId = data.id;
        }
      } catch (error) {
        this.errors.push(error);
        console.error('Error al intentar crear plan de estudios. ' + error);
      }
    },
    async updateSyllabus() {
      try {
        this.alertText = 'Actualizando plan de estudios';
        const { ok, data, message } = await putSyllabus(this.syllabus);
        if (!ok) {
          this.errors.push(message);
        } else {
          this.syllabusId = data.id;
        }
      } catch (error) {
        this.errors.push(
          'Error al intentar actualizar plan de estudios. ' + error
        );
        console.error(
          'Error al intentar actualizar plan de estudios. ' + error
        );
      }
    },
    async assignSyllabusToGroup(groups = []) {
      if (this.syllabusId && groups.length) {
        for (const groupId of groups) {
          const { ok, message } = await postAssignSyllabusToGroup({
            id_grupo: groupId,
            id_plan_estudios: this.syllabusId,
          });
          if (ok) {
            this.alertText = 'Plan de estudios asignado a grupo exitosamente';
            // console.log('Plan de estudios asignado a grupo exitosamente', data);
          } else {
            this.warnings.push(message);
          }
        }
      }
    },
    async unassignSyllabusToGroup(groups = []) {
      if (this.syllabusId && groups.length) {
        for (const groupId of groups) {
          const { ok, message } = await deleteAssignSyllabusToGroup({
            id_grupo: groupId,
            id_plan_estudios: this.syllabusId,
          });
          if (ok) {
            this.alertText =
              'Plan de estudios desasignado a grupo exitosamente';
            // console.log(
            //   'Plan de estudios desasignado a grupo exitosamente',
            //   data
            // );
          } else {
            this.warnings.push(message);
          }
        }
      }
    },
    async checkGroupAssignments() {
      // Aplica solo para modo edición
      // Se debe checar si hay nuevos grupos a asignar
      // o si, por el contrario, se han des seleccionado
      // algunos

      // Checamos si hay que desasignar
      const groupsToUnassign = this.getDifference(
        this.bkSelectedGroups,
        this.selectedGroups
      );
      if (groupsToUnassign.length) {
        await this.unassignSyllabusToGroup(groupsToUnassign);
      }

      // Checamos si hay que asignar
      const groupsToAssign = this.getDifference(
        this.selectedGroups,
        this.bkSelectedGroups
      );
      if (groupsToAssign.length) {
        await this.assignSyllabusToGroup(groupsToAssign);
      }
    },
    getDifference(array1 = [], array2 = []) {
      const set1 = new Set(array1);
      const set2 = new Set(array2);

      const difference = [...set1].filter((item) => !set2.has(item));
      return difference;
    },
    async save() {
      // Validar campos
      // crear o editar
      this.loading = true;
      this.alertText = 'Cargando...';
      this.showAlert = true;

      if (this.isNewMode) {
        if (canAdd(this.resource)) {
          await this.createSyllabus();
          await this.assignSyllabusToGroup(this.selectedGroups);
        } else {
          this.insufficientPermissionAlert();
        }
      } else {
        if (canChange(this.resource)) {
          await this.updateSyllabus();
          await this.checkGroupAssignments();
        } else {
          this.insufficientPermissionAlert();
        }
      }
      this.completeAlert();
    },
    removeChip(id) {
      this.syllabus.materias_curriculares =
        this.syllabus.materias_curriculares.filter((subject) => subject !== id);
    },
    removeExtraChip(id) {
      this.syllabus.materias_extracurriculares =
        this.syllabus.materias_extracurriculares.filter(
          (subject) => subject !== id
        );
    },
  },
};
